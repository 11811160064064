@tailwind base;
@tailwind components;
@tailwind utilities;
.loader {
    border-width: 4px; /* Adjust border thickness */
    border-radius: 50%; /* Makes it round */
    animation: spin 1s linear infinite; /* Spin animation */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  